import React, { useState, useEffect } from "react";
import { GET_STOREINFO, PRODUCT_DETAILS } from "../../urlConstants";
import { useSelector, useDispatch } from "react-redux";
import restUtils from "../../utils/restUtils";
import { Loader } from "semantic-ui-react";
import _ from "lodash";
import ProductDetails from "../common/ProductDetails";
import DirectShipSearch from "../PolicyAsearch/DirectShipSearch";
import utilities from "../../utils/utilities";
import { updateRdc } from "../Login/actions";

const ConfirmItem = (props) => {
  const { storeId, rdc } = useSelector((state) => state.SessionReducer);
  const sku = props?.match?.params?.sku ? props?.match?.params?.sku : null;
  const [product, setProduct] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (_.isEmpty(rdc)) {
      const payLoad = {
        storeNumber: storeId,
      };
      restUtils
        .postDataWithBearer(GET_STOREINFO, payLoad)
        .then((res) => {
          dispatch(updateRdc(res.data.ManinRDCNumber));
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId, rdc]);
  useEffect(() => {
    if (sku && !_.isEmpty(rdc)) {
      const timestamppdp = Date.now();
      const url =
        PRODUCT_DETAILS +
        sku ;
        // +
        // "&storeId=" +
        // storeId +
        // "&rdc=" +
        // rdc +
        // "&grpCollectionId=" +
        // "0" +
        // "&cartTimestamp=" +
        // timestamppdp +
        // "&aliasSKURetailerGrpID=" +
        // "";
      setIsLoading(true);
      restUtils
        .getData(url)
        .then((response) => {
          setProduct(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          utilities.showToast("Error", true);
        });
    }
  }, [sku, storeId, rdc]);
  return (
    <div className="productDetailsWrapper">
      {isLoading && <Loader active />}
      {!_.isEmpty(product) &&
        (!_.isEmpty(product.Item_Number) ? (
          <>
            <ProductDetails product={product} />
          </>
        ) : (
          <>
            <div className="no_itemDisplay">
              <h4>
                No Items were found with provided Item Number, please utilize
                the below Model Number search instead!
              </h4>
            </div>
            <div>
              <DirectShipSearch />
            </div>
          </>
        ))}
    </div>
  );
};

export default ConfirmItem;
