import React from "react";
import history from "../../history";
import scanImg from "../../../src/images/scan.png";
import { getRedirectUriNWH, pdfLink } from "../../urlConstants";
import { Dropdown, DropdownItem, DropdownMenu } from "semantic-ui-react";
import { MobileView } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import { createMedia } from "@artsy/fresnel";
import { Link } from "react-router-dom";
import { retrunToMol } from "../../views/Login/actions";

export const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});
const HeroBar = () => {
  const redirectHome = () => {
    history.push("/home");
  };
  const dispatch = useDispatch();
  const { userName } = useSelector((state) => state.SessionReducer);
  const { storeId } = useSelector((state) => state.SessionReducer);

  const redirectToScan = () => {
    history.push("/scan");
  };
  const downloadPdf = () => {
    const pdfUrl = pdfLink();
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "qr_code.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div
      className="heroHeader ui stackable grid zero-margin"
      computer={3}
      tablet={2}
    >
      <div className="heroHeaderWrapper gridContainer">
        <div className="header-top-left">
          <div className="cursor_p headerLogoImg" onClick={() => dispatch(retrunToMol(getRedirectUriNWH()))}></div>
          <h3>Claims</h3>
        </div>
        <div className="header_icons header-top-right">
          <div className="headerNameStore">
            <p className="name_card">Hello, {userName}</p>
            <p> Store# {storeId}</p>
          </div>
          <div className="userInfoWrapper">
            <div className="header_user_icons">
              <span onClick={downloadPdf}>
                <i className="far fa-qrcode"></i>
              </span>
              <MobileView className="scanBarcode">
                <img src={scanImg} alt={"scan"} onClick={redirectToScan} />
              </MobileView>
              <Dropdown className="userDropDownMenu" text={""} simple>
                <DropdownMenu>                 
                  <DropdownItem>
                    <p
                      onClick={() => dispatch(retrunToMol(getRedirectUriNWH()))}
                    >
                      Return to NETWAREHOUSE
                    </p>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBar;
