import React, { memo, useRef } from "react";
import { createMedia } from "@artsy/fresnel";
import {
  Grid,
  Segment,
  GridColumn,
  GridRow,
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableBody,
  Checkbox,
  Modal,
  ModalContent,
} from "semantic-ui-react";
import ExportExcel from "../common/ExportExcel";
import { Popup } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import _ from "lodash";
import "./styles.scss";
import utilities from "../../utils/utilities";
import { Link } from "react-router-dom";
import pdfLink from "../../assets/TV-Circular_Deadline_Calendar.pdf";
import { useImmer } from "use-immer";
import { v4 as uuidv4 } from "uuid";
export const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    xm: 0,
    sm: 567,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});

export const CustomTable = (props) => {
  const { storeId } = useSelector((state) => state.SessionReducer);
  const {
    product,
    tableContent,
    tableType,
    isError,
    rfaData = [],
    policyAdata = [],
  } = props;
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [statusMsg, setStatusMsg] = useImmer({
    show: false,
    data: {},
  });
  return (
    (product.length > 0 || rfaData.length > 0 || policyAdata.length > 0) && (
      <div>
        {(tableType === "loadFeedbackDetails" ||
          tableType === "claimHistoryDetails" ||
          tableType === "claimDeletedItemTable" ||
          tableType === "policyAclaimHistoryDetails" ||
          tableType === "confirmationPolicyaTable" ||
          tableType === "submitClaimConfirmation") && (
          <>
            <div className="popupExportIcons">
              <div className="exportIcons ">
                {tableType !== "submitClaimConfirmation" && (
                  <ExportExcel
                    name={
                      tableType === "loadFeedbackDetails"
                        ? "loadFeedbackHistoryDetails"
                        : "ClaimsHistoryDetails"
                    }
                    data={product}
                    isPolicyA={props?.isPolicyA ? props?.isPolicyA : false}
                    claimData={props?.claimData ? props?.claimData : false}
                    policyATotalValue={
                      props?.policyATotalValue
                        ? props?.policyATotalValue
                        : false
                    }
                  />
                )}
                <Popup
                  trigger={
                    <i
                      className="print icon"
                      id="printicon"
                      onClick={handlePrint}
                    ></i>
                  }
                  content="Print Pdf"
                  size="mini"
                  position="top right"
                  basic
                ></Popup>
              </div>
            </div>
          </>
        )}
        <div ref={componentRef}>
          {tableType !== "submitClaimConfirmation" && props?.heading && (
            <h3>{props?.heading}</h3>
          )}
          <Segment className="historyPageWrapper">
            <Grid>
              <GridColumn mobile={16} className="historyDataDetails">
                {(tableType === "claimItemDetailsTable" ||
                  tableType === "claimHistoryDetails") && (
                  <div className="claimsDetailsBlock">
                    <p>
                      <strong>Store #: </strong>
                      <span>{storeId}</span>
                    </p>
                    <p>
                      <strong>Claim #: </strong>
                      <span> {props?.claimData?.claimNumber}</span>
                    </p>
                    <p>
                      <strong>Claim Date: </strong>
                      <span>{props?.claimData?.claimDate}</span>
                    </p>
                    <p>
                      <strong>Claim Time: </strong>
                      <span>{props?.claimData?.claimTime}</span>
                    </p>
                    <p>
                      <strong>Origin: </strong>
                      <span> {props?.claimData?.claimOrigin}</span>
                    </p>
                  </div>
                )}
                {tableType === "policyAclaimHistoryDetails" && (
                  <div className="claimsDetailsBlock">
                    <p>
                      <strong>Store #: </strong>
                      <span>{storeId}</span>
                    </p>
                    <p>
                      <strong>Claim #: </strong>
                      <span> {props?.claimData?.claimNumber}</span>
                    </p>
                    <MediaContextProvider>
                      <Media lessThan="md">
                        <p>
                          <strong>Total Claim Value : </strong>
                          <span> ${props?.policyATotalValue}</span>
                        </p>
                      </Media>
                    </MediaContextProvider>
                  </div>
                )}
                {tableType === "confirmationPolicyaTable" && (
                  <div className="claimsDetailsBlock">
                    <p>
                      <strong>Claim #: </strong>
                      <span> {props?.claimData?.claimNumber}</span>
                    </p>
                  </div>
                )}
                {tableType === "submitClaimConfirmation" && (
                  <>
                    {!_.isEmpty(rfaData) && isError.status && (
                      <>
                        {!_.isEmpty(rfaData) &&
                          Array.isArray(rfaData) &&
                          rfaData.map((error) => {
                            return (
                              <>
                                <p>{error.errorMessage}</p>
                                {error.autoShipDocument && (
                                  <p className="viewDetailsPdfLink">
                                    <Link to={pdfLink} target="_blank">
                                      View Details
                                    </Link>
                                  </p>
                                )}
                              </>
                            );
                          })}
                      </>
                    )}
                    {!_.isEmpty(rfaData) && !isError.status && (
                      <div className="confirmationValue">
                        <p>
                          <strong>Store #: </strong>
                          <span>{storeId}</span>
                        </p>
                        <p>
                          Claim Number:{" "}
                          <span>{props?.rfaData?.claimNumber}</span>
                        </p>
                        <p>
                          CRM Case Number:{" "}
                          <span>{props?.rfaData?.crmCaseNumber}</span>
                        </p>
                        <p>
                          The value of your claim is:
                          <span>${props?.rfaData?.totalClaimValue}</span>
                        </p>
                      </div>
                    )}
                    {policyAdata &&
                      policyAdata?.isClaimSubmittedSuccessfully &&
                      policyAdata?.submittedPolicyAClaimsResponse && (
                        <div className="confirmationValue">
                          <p>
                            <strong>Store #: </strong>
                            <span>{storeId}</span>
                          </p>
                          <p>
                            Claim Number:{" "}
                            <span>
                              {
                                policyAdata?.submittedPolicyAClaimsResponse
                                  ?.claimNumber
                              }
                            </span>
                          </p>
                          <p>
                            CRM Case Number:{" "}
                            <span>
                              {
                                policyAdata?.submittedPolicyAClaimsResponse
                                  ?.crmNumber
                              }
                            </span>
                          </p>
                          <p>
                            The value of your claim is:
                            <span>
                              {
                                policyAdata?.submittedPolicyAClaimsResponse
                                  ?.totalClaimValue
                              }
                            </span>
                          </p>
                        </div>
                      )}
                    {!_.isEmpty(policyAdata) &&
                      !policyAdata?.isClaimSubmittedSuccessfully && (
                        <div className="confirmationValue">
                          <p>
                            An error occurred while processing your request.
                            Please try again.
                          </p>
                        </div>
                      )}
                  </>
                )}
                {product && product.length > 0 && (
                  <div>
                    <>
                      <MediaContextProvider>
                        <Media lessThan="md">
                          {product.map((orders) => {
                            /* let recalculateQty = ''
                                          if(props?.recalculateQty && (orders.item_Type === 'Stock' || orders.item_Type === 'DS')) {
                                            // let value = orders.item_Type === 'Stock' ? orders.item_Nbr : orders.model_Nbr
                                             //recalculateQty = props.recalculateQty.find(ele => ele.itemNbrOrModel === value)
                                          }  */
                            return (
                              <Segment className="mobileTable" key={uuidv4()}>
                                <Grid>
                                  {tableContent.map((item) => {
                                    return (
                                      <GridRow>
                                        <GridColumn width={8}>
                                          {item.header}
                                        </GridColumn>
                                        <GridColumn width={8}>
                                          {item.uniqueId === "imgPopup" ? (
                                            <span
                                              className="viewDetailsLink"
                                              onClick={() =>
                                                props.viewImgPopup(orders)
                                              }
                                            >
                                              View Images
                                            </span>
                                          ) : item.uniqueId ===
                                            "loadFeedback_Nbr_history" ? (
                                            <span
                                              className="viewDetailsLink"
                                              onClick={() =>
                                                props.viewDetailsPopup(
                                                  orders.loadFeedback_Id,
                                                )
                                              }
                                            >
                                              {orders[item.key]}
                                            </span>
                                          ) : item.uniqueId ===
                                            "edit/delete" ? (
                                            <div className="actionIcons">
                                              <span>
                                                <i
                                                  className="fal fa-edit"
                                                  onClick={() => {
                                                    if (
                                                      orders.item_Type ===
                                                        "Stock" ||
                                                      orders.item_Type ===
                                                        "DS"
                                                    ) {
                                                      props.recalculate(
                                                        orders,
                                                      );
                                                      return;
                                                    }
                                                    props.handleEdit(
                                                      orders,
                                                    );
                                                  }}
                                                ></i>
                                              </span>
                                              <span>
                                                <i
                                                  className="fas fa-trash-alt"
                                                  onClick={() => {
                                                    props.handleDelete(orders);
                                                  }}
                                                ></i>
                                              </span>
                                            </div>
                                          ) : item.uniqueId === "rfa_edit" ? (
                                            orders.claimType !== "PolicyA" &&
                                            !_.isEmpty(orders?.overAllStatus) &&
                                            (orders?.overAllStatus.includes(
                                              "open",
                                            ) ||
                                              orders?.overAllStatus.includes(
                                                "approve",
                                              )) ? (
                                              <div className="actionIcons">
                                                {orders.item_Type === "Stock" ||
                                                orders.item_Type === "DS" ? (
                                                  <span>
                                                    <i
                                                      className="fas fa-edit"
                                                      onClick={() => {
                                                        props.recalculate(
                                                          orders,
                                                        );
                                                      }}
                                                    ></i>
                                                  </span>
                                                ) : (
                                                  <span>
                                                    <i
                                                      className="fas fa-edit"
                                                      onClick={() => {
                                                        props.handleEdit(
                                                          orders,
                                                        );
                                                      }}
                                                    ></i>
                                                  </span>
                                                )}
                                                <span>
                                                  <i
                                                    className="fas fa-trash-alt"
                                                    onClick={() => {
                                                      props.handleDelete(
                                                        orders,
                                                      );
                                                    }}
                                                  ></i>
                                                </span>
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          ) : item.uniqueId ===
                                            "claimNumberHistory" ? (
                                            <span
                                              className="viewDetailsLink"
                                              onClick={() =>
                                                props.claimsDetails(orders)
                                              }
                                            >
                                              {orders[item.key]}
                                            </span>
                                          ) : item.uniqueId ===
                                            "claimImgPopup" ? (
                                            <span
                                              className="viewDetailsLink"
                                              onClick={() =>
                                                props.viewClaimsImgPopup(orders)
                                              }
                                            >
                                              View Images
                                            </span>
                                          ) : item.uniqueId ===
                                            "claimDatailsItem" ? (
                                            <span>
                                              {" "}
                                              <span
                                                className="viewDetailsLink"
                                                onClick={() =>
                                                  props.itemDetailsPopup(orders)
                                                }
                                              >
                                                {orders[item.key]}
                                              </span>
                                            </span>
                                          ) : item.uniqueId ===
                                            "pendingItemQty" ? (
                                            <span>
                                              {orders?.qty || orders?.quantity}
                                            </span>
                                          ) : item.uniqueId ===
                                            "pendingReturnReason" ? (
                                            <span>
                                              {tableType ===
                                              "submitClaimConfirmation"
                                                ? orders.item_Type ===
                                                    "Stock" ||
                                                  orders.item_Type === "DS"
                                                  ? `${utilities.emptyForNull(orders.reason)}-${utilities.emptyForNull(orders.subReason)}`
                                                  : utilities.emptyForNull(orders.returnReason)
                                                :`${utilities.emptyForNull(orders.reason)}-${utilities.emptyForNull(orders.subReason)}`}
                                            </span>
                                          ) : item.uniqueId ===
                                            "claimDetailsReturnReason" ? (
                                            <span>
                                              {orders.claimType !== "RFA"
                                                ? `${utilities.emptyForNull(orders.reason)}-${utilities.emptyForNull(orders.subReason)}`
                                                :  utilities.emptyForNull(orders.returnReason)}
                                            </span>
                                          ) : (tableType ===
                                              "submitClaimConfirmation" ||
                                              tableType ===
                                                "pendingItemRfaTable") &&
                                            (orders.item_Type === "Stock" ||
                                              orders.item_Type === "DS") ? (
                                            <span>
                                              {item.uniqueId === "doller"
                                                ? utilities.roundWithdollar(
                                                    orders[item.policyaKey],
                                                  )
                                                : orders[item.policyaKey]}
                                            </span>
                                          ) : tableType ===
                                              "submitClaimConfirmation" &&
                                            item.uniqueId === "statusMsg" &&
                                            orders?.status &&
                                            orders?.status === "DECLINED" ? (
                                            <span
                                              className="viewDetailsLink"
                                              onClick={() => {
                                                setStatusMsg((draft) => {
                                                  draft.show = true;
                                                  draft.data = orders;
                                                });
                                              }}
                                            >
                                              DECLINED
                                            </span>
                                          ) : item.uniqueId ===
                                              "deleteCheckBox" &&
                                            orders.status !== "DELETED" ? (
                                            <Checkbox
                                              value={orders.itemNumber}
                                              checked={props.deleteItem.includes(
                                                orders.itemNumber,
                                              )}
                                              onChange={props.handleDeleteItem}
                                            />
                                          ) : item.key !== "" ? (
                                            item.uniqueId === "doller" ? (
                                              utilities.roundWithdollar(
                                                orders[item.key],
                                              )
                                            ) : (
                                              orders[item.key]
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </GridColumn>
                                      </GridRow>
                                    );
                                  })}
                                  {tableType === "submitClaimConfirmation" &&
                                    orders.status === "DECLINED" &&
                                    orders.statusMessage && (
                                      <p className="orderStatusErrorMsgText">
                                        {orders.statusMessage}
                                      </p>
                                    )}
                                </Grid>
                              </Segment>
                            );
                          })}
                        </Media>
                        <Media greaterThanOrEqual="md">
                          <Segment>
                            <Table basic="very" celled collapsing>
                              <TableHeader>
                                <TableRow>
                                  {tableContent.map((item) => {
                                    return (
                                      <TableHeaderCell className={(item.header === "View Images") || (item.header === "Upload Images") ? 'no-print' : ""}>
                                        {item.header}
                                      </TableHeaderCell>
                                    );
                                  })}
                                </TableRow>
                              </TableHeader>
                              <TableBody>
                                {product.map((orders) => {
                                  /* let recalculateQty = ''
                                                if(props?.recalculateQty && (orders.item_Type === 'Stock' || orders.item_Type === 'DS')) {
                                                   let value = orders.item_Type === 'Stock' ? orders.item_Nbr : orders.model_Nbr
                                                   recalculateQty = props.recalculateQty.find(ele => ele.itemNbrOrModel === value)
                                                }*/
                                  return (
                                    <>
                                      <TableRow key={orders["loadFeedback_Id"]}>
                                        {tableContent.map((item) => {
                                          if (item.uniqueId === "imgPopup") {
                                            return (
                                              <TableCell className="no-print">
                                                <span
                                                  className="viewDetailsLink"
                                                  onClick={() =>
                                                    props.viewImgPopup(orders)
                                                  }
                                                >
                                                  View Images
                                                </span>
                                              </TableCell>
                                            );
                                          } else if (
                                            item.uniqueId ===
                                            "loadFeedback_Nbr_history"
                                          ) {
                                            return (
                                              <TableCell>
                                                <span
                                                  className="viewDetailsLink"
                                                  onClick={() =>
                                                    props.viewDetailsPopup(
                                                      orders.loadFeedback_Id,
                                                    )
                                                  }
                                                >
                                                  {orders[item.key]}
                                                </span>
                                              </TableCell>
                                            );
                                          } else if (
                                            item.uniqueId === "edit/delete"
                                          ) {
                                            return (
                                              <TableCell>
                                                <div className="actionIcons">
                                                  <Popup
                                                    trigger={
                                                      <span>
                                                        <i
                                                          className="fal fa-edit"
                                                          onClick={() => {
                                                            if (
                                                              orders.item_Type ===
                                                                "Stock" ||
                                                              orders.item_Type ===
                                                                "DS"
                                                            ) {
                                                              props.recalculate(
                                                                orders,
                                                              );
                                                              return;
                                                            }
                                                            props.handleEdit(
                                                              orders,
                                                            );
                                                          }}
                                                        ></i>
                                                      </span>
                                                    }
                                                    content={
                                                      orders.item_Type ===
                                                        "Stock" ||
                                                      orders.item_Type === "DS"
                                                        ? "Recalculate Qty"
                                                        : "Edit Item"
                                                    }
                                                    size="mini"
                                                    position="top right"
                                                    basic
                                                  ></Popup>
                                                  <Popup
                                                    trigger={
                                                      <span>
                                                        <i
                                                          className="fas fa-trash-alt"
                                                          onClick={() => {
                                                            props.handleDelete(
                                                              orders,
                                                            );
                                                          }}
                                                        ></i>
                                                      </span>
                                                    }
                                                    content="Delete Item"
                                                    size="mini"
                                                    position="top right"
                                                    basic
                                                  ></Popup>
                                                </div>
                                              </TableCell>
                                            );
                                          } else if (
                                            item.uniqueId === "rfa_edit"
                                          ) {
                                            if (
                                              orders.claimType !== "PolicyA" &&
                                              !_.isEmpty(
                                                orders?.overAllStatus,
                                              ) &&
                                              (orders?.overAllStatus.includes(
                                                "open",
                                              ) ||
                                                orders?.overAllStatus.includes(
                                                  "approve",
                                                ))
                                            ) {
                                              return (
                                                <TableCell>
                                                  <div className="actionIcons">
                                                    <Popup
                                                      trigger={
                                                        <span>
                                                          <i
                                                            className="fas fa-edit"
                                                            onClick={() => {
                                                              props.handleEdit(
                                                                orders,
                                                              );
                                                            }}
                                                          ></i>
                                                        </span>
                                                      }
                                                      content="Edit Claim"
                                                      size="mini"
                                                      position="top right"
                                                      basic
                                                    ></Popup>
                                                    <Popup
                                                      trigger={
                                                        <span>
                                                          <i
                                                            className="fas fa-trash-alt"
                                                            onClick={() => {
                                                              props.handleDelete(
                                                                orders,
                                                              );
                                                            }}
                                                          ></i>
                                                        </span>
                                                      }
                                                      content="Delete Claim"
                                                      size="mini"
                                                      position="top right"
                                                      basic
                                                    ></Popup>
                                                  </div>
                                                </TableCell>
                                              );
                                            } else {
                                              return <TableCell></TableCell>;
                                            }
                                          } else if (
                                            item.uniqueId ===
                                            "claimNumberHistory"
                                          ) {
                                            return (
                                              <TableCell>
                                                <span
                                                  className="viewDetailsLink"
                                                  onClick={() =>
                                                    props.claimsDetails(orders)
                                                  }
                                                >
                                                  {orders[item.key]}
                                                </span>
                                              </TableCell>
                                            );
                                          } else if (
                                            item.uniqueId === "claimImgPopup"
                                          ) {
                                            return (
                                              <TableCell className="no-print">
                                                <span
                                                  className="viewDetailsLink"
                                                  onClick={() =>
                                                    props.viewClaimsImgPopup(
                                                      orders,
                                                    )
                                                  }
                                                >
                                                  View Images
                                                </span>
                                              </TableCell>
                                            );
                                          } else if (
                                            item.uniqueId ===
                                              "deleteCheckBox" &&
                                            orders.status !== "DELETED"
                                          ) {
                                            return (
                                              <TableCell>
                                                <Checkbox
                                                  value={orders.itemNumber}
                                                  onChange={
                                                    props.handleDeleteItem
                                                  }
                                                />
                                              </TableCell>
                                            );
                                          } else if (
                                            item.uniqueId === "claimDatailsItem"
                                          ) {
                                            return (
                                              <TableCell>
                                                <span
                                                  className="viewDetailsLink"
                                                  onClick={() =>
                                                    props.itemDetailsPopup(
                                                      orders,
                                                    )
                                                  }
                                                >
                                                  {orders[item.key]}
                                                </span>
                                              </TableCell>
                                            );
                                          } else if (
                                            item.uniqueId === "pendingItemQty"
                                          ) {
                                            return (
                                              <TableCell>
                                                {orders?.qty ||
                                                  orders?.quantity}
                                              </TableCell>
                                            );
                                          } else if (
                                            item.uniqueId ===
                                            "pendingReturnReason"
                                          ) {
                                            return (
                                              <TableCell>
                                                {tableType ===
                                                "submitClaimConfirmation"
                                                  ? orders.item_Type ===
                                                      "Stock" ||
                                                    orders.item_Type === "DS"
                                                    ? `${utilities.emptyForNull(orders.reason)}-${utilities.emptyForNull(orders.subReason)}`
                                                  : utilities.emptyForNull(orders.returnReason)
                                                :`${utilities.emptyForNull(orders.reason)}-${utilities.emptyForNull(orders.subReason)}`}
                                              </TableCell>
                                            );
                                          } else if (
                                            item.uniqueId ===
                                            "claimDetailsReturnReason"
                                          ) {
                                            return (
                                              <TableCell>
                                                {orders.claimType !== "RFA"
                                                  ? `${utilities.emptyForNull(orders.reason)}-${utilities.emptyForNull(orders.subReason)}`
                                                  : utilities.emptyForNull(orders.returnReason)}
                                              </TableCell>
                                            );
                                          } else if (
                                            (tableType ===
                                              "submitClaimConfirmation" ||
                                              tableType ===
                                                "pendingItemRfaTable") &&
                                            (orders.item_Type === "Stock" ||
                                              orders.item_Type === "DS")
                                          ) {
                                            return (
                                              <TableCell>
                                                {item.uniqueId === "doller"
                                                  ? utilities.roundWithdollar(
                                                      orders[item.policyaKey],
                                                    )
                                                  : orders[item.policyaKey]}
                                              </TableCell>
                                            );
                                          } else if (
                                            tableType ===
                                              "submitClaimConfirmation" &&
                                            item.uniqueId === "statusMsg" &&
                                            orders?.status &&
                                            orders?.status === "DECLINED"
                                          ) {
                                            return (
                                              <TableCell>
                                                <span
                                                  className="viewDetailsLink"
                                                  onClick={() => {
                                                    setStatusMsg((draft) => {
                                                      draft.show = true;
                                                      draft.data = orders;
                                                    });
                                                  }}
                                                >
                                                  DECLINED
                                                </span>
                                              </TableCell>
                                            );
                                          }
                                          return (
                                            <TableCell>
                                              {item.key !== ""
                                                ? item.uniqueId === "doller"
                                                  ? utilities.roundWithdollar(
                                                      orders[item.key],
                                                    )
                                                  : orders[item.key]
                                                : ""}
                                            </TableCell>
                                          );
                                        })}
                                      </TableRow>
                                      {tableType ===
                                        "submitClaimConfirmation" &&
                                        orders.status === "DECLINED" &&
                                        orders.statusMessage && (
                                          <TableRow className="orderStatusErrorMsg">
                                            <TableCell colSpan={11}>
                                              <p>{orders.statusMessage}</p>
                                            </TableCell>
                                          </TableRow>
                                        )}
                                    </>
                                  );
                                })}
                                {tableType === "policyAclaimHistoryDetails" &&
                                  props?.isPolicyA && (
                                    <TableRow>
                                      <TableCell
                                        colSpan={11}
                                        style={{
                                          textAlign: "right",
                                          fontSize: "1rem",
                                        }}
                                      >
                                        Total Claim Value : $
                                        {props?.policyATotalValue}
                                      </TableCell>
                                      <TableCell></TableCell>
                                    </TableRow>
                                  )}
                              </TableBody>
                            </Table>
                          </Segment>
                        </Media>
                      </MediaContextProvider>
                    </>
                  </div>
                )}
              </GridColumn>
            </Grid>
          </Segment>
          {tableType === "loadFeedbackDetails" && (
            <p className="commentInfoBox">
              Feedback/Comment: <span>{product[0].loadFeedback_Comments}</span>
            </p>
          )}
          {tableType === "claimItemDetailsTable" && (
            <div className="itemAdditionalInfo">
              <h4>Additional Information</h4>
              <div className="infoList">
                <p>
                  <strong>Invoice#: </strong>
                  <span>{product[0].invoiceNumber}</span>
                </p>
                <p>
                  <strong>Shipped: </strong>
                  <span>{product[0].shipped}</span>
                </p>
                <p>
                  <strong>Invoice RDC: </strong>
                  <span>{product[0].invoiceRDC}</span>
                </p>
                <p>
                  <strong>Item Type: </strong>
                  <span>
                    {product[0].itemType + "" + product[0].itemSubType}
                  </span>
                </p>
                <p>
                  <strong>Retrun Reason: </strong>
                  <span>{utilities.emptyForNull(product[0].returnReason)}</span>
                </p>
              </div>
              <div className="infoComment">
                <p>
                  <strong>Comment: </strong>
                  <span>{product[0].comments}</span>
                </p>
              </div>
            </div>
          )}
          {tableType === "policyAclaimHistoryDetails" && (
            <>
              <div
                className="itemAdditionalInfo"
                style={{ background: "#fff" }}
              >
                <h4>Additional Information</h4>
                <div style={{ padding: "0.5rem" }}>
                  <p>
                    The entire claim is held until all in process items are
                    resolved.
                  </p>
                  <p>* Pending the monthly process</p>
                  <p>
                    If you have any questions about this claim, please feel free
                    to call toll-free 877-594-2269.
                  </p>
                </div>
              </div>
              {props.serialNumberDetails.length > 0 && (
                <>
                  <div className="serialNoDiv">
                    <h4>Additional Water Heater Information</h4>
                    <ExportExcel
                      name={"serialNumberDetails"}
                      data={props.serialNumberDetails}
                    />
                  </div>
                  <Segment className="historyPageWrapper">
                    <Grid>
                      <GridColumn mobile={16} className="historyDataDetails">
                        <MediaContextProvider>
                          <Media lessThan="md">
                            <Segment className="mobileTable">
                              <Grid>
                                {props.serialNumberDetails.map((item) => {
                                  return (
                                    <GridRow>
                                      <GridColumn width={8}>
                                        Serial No
                                      </GridColumn>
                                      <GridColumn width={8}>
                                        {item.serial_nbr}
                                      </GridColumn>
                                      <GridColumn width={8}>
                                        Warranty Reason
                                      </GridColumn>
                                      <GridColumn width={8}>
                                        {item.warrantyDescription}
                                      </GridColumn>
                                      <GridColumn width={8}>
                                        Return Reason
                                      </GridColumn>
                                      <GridColumn width={8}>
                                        {utilities.emptyForNull(item.returnReason)}
                                      </GridColumn>
                                    </GridRow>
                                  );
                                })}
                              </Grid>
                            </Segment>
                          </Media>

                          <Media greaterThanOrEqual="md">
                            <Segment>
                              <Table basic="very" celled collapsing>
                                <TableHeader>
                                  <TableRow>
                                    <TableHeaderCell>Serial No</TableHeaderCell>
                                    <TableHeaderCell>
                                      Warranty Reason
                                    </TableHeaderCell>
                                    <TableHeaderCell>
                                      Return Reason
                                    </TableHeaderCell>
                                  </TableRow>
                                </TableHeader>
                                <TableBody>
                                  {props.serialNumberDetails.map((item) => {
                                    return (
                                      <TableRow>
                                        <TableCell>{item.serial_nbr}</TableCell>
                                        <TableCell>
                                          {item.warrantyDescription}
                                        </TableCell>
                                        <TableCell>
                                          {utilities.emptyForNull(item.returnReason)}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </Segment>
                          </Media>
                        </MediaContextProvider>
                      </GridColumn>
                    </Grid>
                  </Segment>
                </>
              )}
            </>
          )}
        </div>
        {statusMsg.show && (
          <Modal className="statusMsgPoup" open={statusMsg.show}>
            <ModalContent>
              <div>
                {statusMsg.data?.rsStatusMessageCode === "31" ||
                statusMsg.data?.rsStatusMessageCode === "23" ||
                statusMsg.data?.rsStatusMessageCode === "30" ? (
                  <p>{`Warehouse claims are not accepted after 30-calendar days of invoice date.This item is ${statusMsg.data?.status} because ${statusMsg.data?.rsStatusMessage}`}</p>
                ) : (
                  <p>{`The status of this item is ${statusMsg.data?.status} because ${statusMsg.data?.rsStatusMessage}`}</p>
                )}
              </div>
              <div>
                <button
                  className="ui button"
                  onClick={() =>
                    setStatusMsg((draft) => {
                      draft.show = false;
                      draft.data = {};
                    })
                  }
                >
                  Close
                </button>
              </div>
            </ModalContent>
          </Modal>
        )}
      </div>
    )
  );
};

export default memo(CustomTable);
