import React from "react";
import no_img_avlbl from "../images/image_not_found.jfif";
// import {NO_IMAGE} from "../../../urlConstants";
import { NO_IMAGE } from "../../urlConstants";

export const fetchImage = {
  getImage: (props) => {
    const { altUrl } = props;
    return (
      <img
        {...props}
        onLoad={function ({ target: image }) {
          if (image && image.offsetWidth <= 1) {
            image.src = altUrl ? altUrl : NO_IMAGE;
          }
        }}
        onError={(e) => {
          e.target.src = NO_IMAGE;
        }}
        alt="img"
      />
    );
  },
};

export default fetchImage;
